import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Krikelkrakel from "../components/krikelkrakel"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="container mx-auto" style={{ maxWidth: `1000px`, marginBottom: `0rem`, alignSelf: `center` }}>
      <Krikelkrakel />
    </div>
  </Layout>

)

export default IndexPage

